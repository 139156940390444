import Vue from "vue";
import VueRouter from "vue-router";
import LoginNew from "@/views/LoginNew.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginNew
  },
  {
    path: "/activate",
    name: "Activate",
    component: () => import("@/views/ActivateAccountNew.vue")
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: () => import("@/views/ForgotPasswordNew.vue")
  },
  {
    path: "/reset",
    name: 'Reset',
    component: () => import("@/views/ResetPasswordNew.vue")
  },
  {
    path: "/terms",
    name: "TermsAndConditions",
    component: () => import("@/views/Terms.vue")
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/views/PrivacyPolicy.vue")
  },
  {
    path: "/panelSelection",
    name: "PanelSelection",
    props: true,
    component: () => import("@/views/PanelSelectionPage.vue")
  },
  {
    path: "/buildingSelection",
    name: "BuildingSelection",
    // lazy-loaded when the route is visited.
    component: () => import("@/views/BuildingSelectionPage.vue")
  },
  {
    path: '/viewPanel',
    name: 'ViewPanel',
    component: () => import(/* webpackChunkName: "panel" */ '@/views/ViewPanel.vue')
  },
  {
    path: '/viewBreakerData',
    name: 'ViewBreakerData',
    component: () => import(/* webpackChunkName: "panel" */ '@/views/ViewBreakerData.vue')
  },
  {
    path: '/viewPanelData',
    name: 'ViewPanelData',
    component: () => import(/* webpackChunkName: "panel" */ '@/views/ViewPanelData.vue')
  },
  {
    path: '/manageUsers',
    name: 'Manage',
    component: () => import(/* webpackChunkName: "panel" */ '@/views/ManageUsers.vue')
  },
  {
    path: '/locations',
    name: 'Locations',
    component: () => import(/* webpackChunkName: "panel" */ '@/views/Locations.vue')
  },
  {
    path: '/UserPage',
    name: 'UserPage',
    component: () => import('@/views/UserPage/Home.vue')
  },
  {
    path: '/UserPage/Account',
    name: 'UserAccount',
    component: () => import('@/views/UserPage/Account.vue')
  },
  {
    path: '/UserPage/Data',
    name: 'UserData',
    component: () => import('@/views/UserPage/Stats.vue')
  },
  {
    path: '/UserPage/Schedule',
    name: 'UserSchedule',
    component: () => import('@/views/UserPage/Schedule.vue')
  },
  {
    path: '/UserPage/Help',
    name: 'UserSupport',
    component: () => import('@/views/UserPage/Help.vue')
  },
  {
    path: '/UserPage/Rates',
    name: 'UserRates',
    component: () => import('@/views/UserPage/Rates.vue')
  },
  {
    path: '/UserPage/Billing',
    name: 'UserBilling',
    component: () => import('@/views/UserPage/Billing.vue')
  },
  {
    path: '/UserPage/Support',
    name: 'UserSupport',
    component: () => import('@/views/UserPage/Support.vue')
  },
  {
    path: '/UserPage/MFA',
    name: 'UserMFA',
    component: () => import('@/views/UserPage/MultiFactorAuth.vue')
  },
  // {
  //   path: '/UserPage/TermsAndConditions',
  //   name: 'UserTerms',
  //   component: () => import('@/views/UserPage/Terms.vue')
  // },
  {
    path: '/debugpage',
    name: 'debugpage',
    component: () => import('@/views/DebugPage.vue')
  },
  {
    path: '/historypage',
    name: 'historypage',
    component: () => import('@/views/HistoryPage.vue')
  },
  {
    path: '/loadThrottling',
    name: 'LoadThrottling',
    component: () => import('@/views/LoadThrottling.vue')
  },
  {
    path: '/billingReports',
    name: 'Billing Reports',
    component: () => import('@/views/BillingReports.vue')
  },
  {
    path: '/sessionReports',
    name: 'Session Reports',
    component: () => import('@/views/SessionReports.vue')
  },
  {
    path: '/dltaReports',
    name: 'DLTA Reports',
    component: () => import('@/views/DLTAReports.vue')
  },
  {
    path: '/breakdownReports',
    name: 'Breakdown Reports',
    component: () => import('@/views/BreakdownReports.vue')
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import('@/views/Statistics.vue')
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: () => import('@/views/Organizations.vue')
  },
  {
    path: '/createUsers',
    name: 'CreateU',
    component: () => import('@/views/CreateUsers.vue')
  },
  {
    path: '/managerHome',
    name: 'ManagerHome',
    component: () => import('@/views/ManagerHome.vue')
  },
  {
    path: '/technicianHome',
    name: 'TechnicianHome',
    component: () => import('@/views/TechnicianHome.vue')
  },
  {
    path: '/uploadImage',
    name: 'UploadImage',
    component: () => import('@/views/UploadImage.vue')
  },
  {
    path: '/paymentPlans',
    name: 'Payment Plans',
    component: () => import('@/views/PaymentPlans.vue')
  },
  {
    path: '/oneTimeFees',
    name: 'One Time Fees',
    component: () => import('@/views/OneTimeFees.vue')
  }
];

const router = new VueRouter({
  routes
});

export default router;
